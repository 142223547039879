import React from "react";
import { hideShow } from "../utils/Helper";
import { collapseMenu } from "../utils/Helper";
import { collapseMenuinner } from "../utils/Helper";
import { collapseiconMenuinner } from "../utils/Helper";
import { Link } from "react-router-dom";


const Sidebar = () => {
    return (
        <div id="new">
            <div className="left side-menu newsidebar" id="sidebar">
                <button
                    type="button"
                    className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect" id="sidebarCollapse"
                    onClick={() => {
                        hideShow();
                    }}
                >
                    <i className="ion-close" />
                </button>
                <div className="left-side-logo d-block d-lg-none">
                    <div className="text-center">
                        <a href="/" className="logo">
                            <img src="assets/images/logo.png" height={60} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className="sidebar-inner slimscrollleft">
                    <div id="sidebar-menu">
                        <ul>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/dashboard" }} data-toggle="tooltip" title="Dashboard">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-bell-ring notificationicon position" style={{ "paddingRight": "10px" }}></i><span style={{ "fontSize": "17px" }} className="box position">Dashboard</span></div>
                                </Link>
                            </li>

                            <li className="has_sub">
                                <a
                                    href="#"
                                    className="waves-effect active"
                                    onClick={() => {
                                        collapseMenu();
                                    }}
                                    id="arrowsubmenu" data-toggle="tooltip" title="Masters">

                                    <i className="dripicons-briefcase verticalMaster position" onClick={() => {
                                        collapseiconMenuinner();
                                    }} data-bs-placement="right"></i> <span style={{ "fontSize": "17px" }} className="innerverticalMaster box position" id="arrowposition" > Masters </span>
                                    <span className="menu-arrow float-right" id="arrowhide" onClick={() => {
                                        collapseMenuinner();
                                    }}>
                                        <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                </a>
                                <ul className="list-unstyled" id="innerlist">
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon "><i className="mdi mdi-dots-vertical"></i> Vertical Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-drag-vertical"></i> Sub Vertical Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-google-circles-extended"></i> Activity Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-google-circles-communities"></i> Sub Activity Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-rowing"></i> Habit Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-sign-caution"></i> MileStone Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-hospital"></i> Career Profile Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-drag-vertical"></i> Sub Vertical Activity Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-drag-vertical"></i> Sub Vertical Episode Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/childMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-human-child"></i> Child Master</div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to={{ pathname: "/verticalMaster" }}>
                                            <div className="sb-nav-link-icon"><i className="mdi mdi-account-multiple"></i> Parent Master</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/helpCenter" }} data-toggle="tooltip" title="Help center">
                                    <div className="sb-nav-link-icon" ><i className=" mdi mdi-account-box position" style={{ "paddingRight": "10px" }}></i><span id="helpHide" style={{ "fontSize": "17px" }} className="box position">Help center</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/deleteAccount" }} data-toggle="tooltip" title="Delete Account">
                                    <div className="sb-nav-link-icon" ><i className="mdi mdi-account-remove position" style={{ "paddingRight": "10px" }}></i><span id="DeleteAccount" style={{ "fontSize": "17px" }} className="box position">Delete Account</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/notification" }} data-toggle="tooltip" title="Notification">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-bell-ring notificationicon position" style={{ "paddingRight": "10px" }}></i><span style={{ "fontSize": "17px" }} className="box position">Notification</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/cronJob" }} data-toggle="tooltip" title="Cron job">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-alarm-check cronjobicon position" style={{ "paddingRight": "10px" }} /><span style={{ "fontSize": "17px" }} className="box position">Cron job</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/subuser" }} data-toggle="tooltip" title="Sub User">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-account-check subusericon position" style={{ "paddingRight": "10px" }} /><span style={{ "fontSize": "17px" }} className="box position">
                                        Subscribed Users</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/PlanMaster" }} data-toggle="tooltip" title="Plan Manager">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-account-check subusericon position" style={{ "paddingRight": "10px" }} /><span style={{ "fontSize": "17px" }} className="box position">
                                        Plan Manager</span></div>
                                </Link>
                            </li>
                            <li>
                                <Link className="nav-link" to={{ pathname: "/ChildrenProfile" }} data-toggle="tooltip" title="Plan Manager">
                                    <div className="sb-nav-link-icon"><i className="mdi mdi-account-child ChildrenProfileicon position" style={{ "paddingRight": "10px" }} /><span style={{ "fontSize": "17px" }} className="box position">
                                        Children Profile</span></div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
