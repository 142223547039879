import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import API from "../../API";

const ParentForm = () => {

    const initialValues = {
        parent_name: "",
        whatsapp_number: "",
        email: "",
        child_age: "",
        book_ids: [],
        total_amount: "",
        payment_method: "",
        remark: ""
    };

    const [bookOptions, setBookOptions] = useState([]);
    const [message, setMessage] = useState({
        alert: "primary",
        text: ""
    });


    const validationSchema = Yup.object({
        parent_name: Yup.string().required("Parent name is required"),
        whatsapp_number: Yup.string()
            .matches(/^[0-9]{10,15}$/, "Enter a valid WhatsApp number")
            .required("WhatsApp number is required"),
        // email: Yup.string().email("Invalid email").required("Email is required"),
        // child_age: Yup.number()
        //     .min(1, "Age must be at least 1")
        //     .required("Child's age is required"),
        // book_ids: Yup.array().min(1, "Select at least one book ID").required(),
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const res = await API.post("books-sold", {
                ...values,
                book_ids: values.book_ids.map((book) => book.value),
                source: "Website",
            });
            console.log("res:", res.data);
            resetForm();
            setMessage({
                alert: "success",
                text: res.data?.message
            })
        } catch (error) {
            console.error("Error submitting the form", error);
            setMessage({
                alert: "danger",
                text: error.response?.data?.errors || error.response?.data?.message
            })
        } finally {
            setSubmitting(false);
        }
    };

    const getAllBooks = async () => {
        try {
            const res = await API.get("books");
            let data = res.data?.data
            setBookOptions(data.map(item => ({ value: item.id, label: item.name })))
            // console.log("Response:", res.data);
        } catch (error) {
            console.error("Error submitting the form", error);
        }
    }

    useEffect(() => {
        getAllBooks()
    }, []);

    useEffect(() => {
        if (message.text !== "") {
            setTimeout(() => {
                setMessage({
                    alert: "primary",
                    text: ""
                })
            }, 5000);
        }
    }, [message]);


    return (
        <div
            className=""
            style={{
                backgroundImage: 'linear-gradient(to right bottom, #eeb56d, #eea656, #ef963f, #ef8429, #f07111)',
                justifyContent: "center",
                height: "100vh",
                overflowY: "auto",
                padding: "5% 0",
                display: 'flex'
            }}
        >

            <div className="content-center">
                <div className="content-desc-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-10 col-sm-10 col-11">
                                <div className="card shadow-lg p-3 mb-5 bg-body roundedcorner">
                                    <div className="card-body">
                                        <h3 className="text-center mt-0 m-b-15">
                                            <img
                                                src="assets/images/logo.png"
                                                height={90}
                                                alt="logo"
                                            />
                                        </h3>
                                        <div className="p-2">
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={handleSubmit}
                                            >
                                                {({ values, setFieldValue, isSubmitting, handleChange }) => (
                                                    <Form>
                                                        <div className="row">
                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="parent_name" className="form-label">
                                                                    Parent Name
                                                                    <font color="red"> *</font>
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    id="parent_name"
                                                                    name="parent_name"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage
                                                                    name="parent_name"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="whatsapp_number" className="form-label">
                                                                    WhatsApp Number
                                                                    <font color="red"> *</font>
                                                                </label>
                                                                <Field
                                                                    type="number"
                                                                    id="whatsapp_number"
                                                                    name="whatsapp_number"
                                                                    className="form-control"
                                                                    onChange={e => {
                                                                        if (e.target.value.length <= 10) {
                                                                            handleChange(e)
                                                                        }
                                                                    }}
                                                                />
                                                                <ErrorMessage
                                                                    name="whatsapp_number"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="email" className="form-label">
                                                                    Email Id
                                                                </label>
                                                                <Field
                                                                    type="email"
                                                                    id="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage
                                                                    name="email"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="child_age" className="form-label">
                                                                    Child's Age
                                                                </label>
                                                                <Field
                                                                    type="number"
                                                                    id="child_age"
                                                                    name="child_age"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage
                                                                    name="child_age"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="book_ids" className="form-label">
                                                                    Books Purchased
                                                                </label>
                                                                <Select
                                                                    id="book_ids"
                                                                    name="book_ids"
                                                                    options={bookOptions}
                                                                    isMulti
                                                                    value={values.book_ids}
                                                                    onChange={(selectedOptions) =>
                                                                        setFieldValue("book_ids", selectedOptions)
                                                                    }
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                />
                                                                <ErrorMessage
                                                                    name="book_ids"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-lg-6 col-12">
                                                                <label htmlFor="total_amount" className="form-label">
                                                                    Total Amount
                                                                </label>
                                                                <Field
                                                                    type="number"
                                                                    id="total_amount"
                                                                    name="total_amount"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage
                                                                    name="total_amount"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-lg-6 col-12">
                                                                <label htmlFor="payment_method" className="form-label">
                                                                    Payment Method
                                                                </label>
                                                                <Field
                                                                    as="select"
                                                                    id="payment_method"
                                                                    name="payment_method"
                                                                    className="form-control"
                                                                >
                                                                    <option value="" disabled>
                                                                        Select Payment Method
                                                                    </option>
                                                                    <option value="cash">Cash</option>
                                                                    <option value="upi">UPI</option>
                                                                    <option value="other">Other</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name="payment_method"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                            <div className="mb-3 col-12">
                                                                <label htmlFor="remark" className="form-label">
                                                                    Remark
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    id="remark"
                                                                    name="remark"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage
                                                                    name="remark"
                                                                    component="div"
                                                                    className="text-danger"
                                                                />
                                                            </div>

                                                        </div>
                                                        {message &&
                                                            message.text !== "" &&
                                                            <div className={`alert alert-${message.alert}`}>
                                                                {message.text}
                                                            </div>}
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary btn-block"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? "Submitting..." : "Submit"}
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ParentForm;
