import React, { useState, useEffect } from "react";

import { Formik, Field } from "formik";
import * as Yup from "yup";
import API from "./API";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser, setMessage, setLogout } from "./Store/AllReducer/loginSlice";
import { useLocation } from "react-router-dom";

const Login = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  const [loggedUser, setLoggedUser] = useState();

  const cookies = new Cookies();

  let myValues = {
    username: cookies.get('Username'),
    password: cookies.get('Password'),
    rememberMe: cookies.get("RemeberMe"),
  };


  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/") {
      localStorage.clear();
      // setCurrentUser(); //---------------logout redux
      dispatch(setLogout());
    }
  }, []);

  useEffect(() => {

    if (loggedUser && loggedUser.role) {
      console.log("Logged User: ", loggedUser);
      let RedirectPath = "/";
      if (loggedUser.role === "ADMIN") {
        RedirectPath = '/adminHome';
      } else if (loggedUser.role === "SUPPORT") {
        RedirectPath = '/supportHome';
      }
      localStorage.setItem("token", JSON.stringify(loggedUser.token));
      dispatch(setCurrentUser(loggedUser.data));
      navigate(RedirectPath);
    }

  }, [loggedUser]);

  return (
    <Formik
      initialValues={myValues}
      onSubmit={async (values, actions) => {
        // validateAdmin(values, setShow, setMsg, navigate, setCurrentUser);
        validateAdmin(values, navigate, dispatch, setCurrentUser, setLoggedUser, setMessage);

        actions.setSubmitting(false);
        actions.resetForm({
          values: {
            username: "",
            password: "",
            rememberMe: false
          },
        });

        if (values.rememberMe === true) {
          cookies.set("Username", values.username, { path: "/" });
          cookies.set("Password", values.password, { path: "/" });
          cookies.set("RemeberMe", true, { path: "/" });
        } else {
          cookies.remove("Username", "", { path: "/" });
          cookies.remove("Password", "", { path: "/" });
          cookies.remove("RemeberMe", false, { path: "/" });
        }
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required("Username Required"),
        password: Yup.string().required("Password is Required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div>
            <div className="accountbg">
              <div className="content-center">
                <div className="content-desc-center">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-lg-3">
                        {/* <img src="assets/images/logo.png" class="img-fluid" alt="Responsive image"></img> */}
                      </div>
                      <div className="col-lg-6  col-md-6 col-xl-6" style={{ "marginRight": '1%' }}>
                        <div className="card shadow-lg p-3 mb-5 bg-body roundedcorner">
                          <div className="card-body">
                            <h3 className="text-center mt-0 m-b-15">
                              <a href="/" className="logo logo-admin">
                                <img
                                  src="assets/images/logo.png"
                                  height={90}
                                  alt="logo"
                                />
                              </a>
                            </h3>
                            <h4 className="text-muted text-center font-18">
                              <b>Sign In</b>
                            </h4>
                            <div className="p-2">
                              <form
                                className="form-horizontal m-t-20"
                                onSubmit={handleSubmit}
                              >
                                <div className="form-group row">
                                  <div className="col-12">
                                    <input
                                      name="username"
                                      id="username"
                                      className="form-control"
                                      type="text"
                                      placeholder="Username"
                                      value={values.username === undefined ? '' : values.username}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.username && touched.username && (
                                      <div className="alert alert-danger">
                                        {errors.username}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-12">
                                    <input
                                      name="password"
                                      id="password"
                                      className="form-control"
                                      type="password"
                                      placeholder="Password"
                                      value={values.password === undefined ? '' : values.password}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.password && touched.password && (
                                      <div className="alert alert-danger">
                                        {errors.password}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <div className="col-12">
                                    <div>
                                      <Field type="checkbox" id="rememberMe" onChange={handleChange} value={true} name="rememberMe" checked={(values.rememberMe === undefined || values.rememberMe === false) ? false : true} />
                                      <label
                                        htmlFor="rememberMe"
                                      >
                                        &nbsp;&nbsp;Remember me
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group text-center row m-t-20">
                                  <div className="col-12">
                                    <button
                                      className="btn btn-primary btn-block waves-effect waves-light"
                                      type="submit"
                                      disabled={isSubmitting}
                                    >
                                      Log In
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

async function validateAdmin(values, navigate, dispatch, setCurrentUser, setLoggedUser, setMessage) {
  await API.post("Authenticate/admin", {
    username: values.username,
    password: values.password,
  })
    .then((res) => {
      if (res.data.status === "success") {
        if (res.data.role === "ADMIN") {
          setLoggedUser(res.data);
          dispatch(setCurrentUser(res.data.data));
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
          navigate("/adminHome", { state: { data: res.data.data } });
        }
      } else {
        dispatch(setCurrentUser({}));
        dispatch(setMessage({
          show: true,
          msg: res.response.data.message,
          responseType: res.data.status
        }));
      }
    })
    .catch(function (error) {
      console.log("Err:", error.response);
      dispatch(setCurrentUser({}));
      dispatch(setMessage({
        show: true,
        msg: error.response.data.message,
        responseType: error.response.data.status
      }));
    });
}

export default Login;
